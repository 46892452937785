<template>
  <div>
    <h2 class="main-color">System Log</h2>
    <v-data-table
      v-can="'list-financial-status'"
      :options.sync="options"
      :headers="headers"
      :items="items"
      :loading="loading"
      loading-text="Loading... Please wait"
      class="elevation-1 level1"
      hide-default-footer
    >
      <template v-slot:top>
        <v-row>
          <v-col class="text-center">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-width="200"
              max-width="400px"
              offset-x
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="main-btn py-2" v-bind="attrs" v-on="on">
                  <i
                    class="fas fa-filter main-color fa-lg"
                    style="color: #fff !important; font-size: 20px;"
                  ></i>
                </v-btn>
              </template>

              <v-card>
                <v-list>
                  <v-form ref="form" v-model="valid">
                    <v-list-item class="pt-3">
                      <v-text-field v-model="search" class="search-input" solo>
                        <template slot="append">
                          <div class="icon-div">
                            <v-btn v-if="search" icon @click="searchLog()">
                              <v-icon>mdi-arrow-right</v-icon>
                            </v-btn>
                            <v-icon v-if="!search">search</v-icon>
                          </div>
                        </template>
                      </v-text-field>
                    </v-list-item>

                    <v-list-item>
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="filter.dateFrom"
                            :rules="[
                              fromToValidation(
                                filter.dateFrom,
                                filter.dateTo,
                                $t(
                                  'Start date should be less than or equal to end date'
                                )
                              ),
                            ]"
                            label="Date From"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            solo
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="filter.dateFrom"
                          @input="menu2 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-list-item>

                    <v-list-item>
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="filter.dateTo"
                            :rules="[
                              fromToValidation(
                                filter.dateFrom,
                                filter.dateTo,
                                $t(
                                  'Start date should be less than or equal to end date'
                                )
                              ),
                            ]"
                            label="Date To"
                            append-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            solo
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="filter.dateTo"
                          @input="menu1 = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-list-item>

                    <v-list-item>
                      <v-autocomplete
                        v-model="selectedUsers"
                        item-text="name"
                        item-value="id"
                        :items="users"
                        chips
                        :label="$t('Users')"
                        multiple
                        solo
                      ></v-autocomplete>
                    </v-list-item>
                  </v-form>
                </v-list>

                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn class="main-btn" text @click="filterData()">
                    {{ $t("Filter") }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="12" class="py-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </template>
    </v-data-table>

    <div class="text-center pt-2">
      <v-pagination
        v-if="length > 0"
        v-model="page"
        :length="length"
        :circle="true"
        :page="page"
        :total-visible="totalVisible"
        next-icon="mdi-menu-right"
        prev-icon="mdi-menu-left"
      >
      </v-pagination>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "../../mixins/validationMixin";

export default {
  name: "logging",
  mixins: [validationMixin],
  data() {
    return {
      valid: false,
      menu: false,
      filter: {
        dateFrom: "",
        dateTo: "",
      },
      menu1: false,
      menu2: false,
      search: "",
      search_icon: false,
      page: 1,
      length: 3,
      totalVisible: 3,
      totalItems: 3,
      loading: false,
      options: {
        itemsPerPage: 15,
      },
      headers: [
        {
          text: "The Action",
          sortable: false,
          value: "description",
        },
        { text: "By User Account", value: "username", sortable: false },
        { text: "Time Stamp", value: "created_at", sortable: false },
      ],
      items: [],
      users: [],
      selectedUsers: [],
      logView: "",
    };
  },
  watch: {
    page: {
      handler() {
        console.log(this.page);
        this.getDataFromApi();
      },
      deep: true,
    },
    filter: {
      handler() {
        // this.getDataFromApi();
        console.log(this.filter.dateFrom);
      },
      deep: true,
    },
  },
  methods: {
    filterData() {
      if (this.valid) {
        this.menu = false;
        this.getDataFromApi();
      } else {
        this.$refs.form.validate();
      }
    },
    searchLog() {
      console.log("search");
      this.getDataFromApi();
    },

    getAllUsers() {
      axios
        .get(this.getApiUrl + "/log/getAllUsers", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.users = response.data.data;
        });
    },
    getDataFromApi() {
      axios
        .get(
          this.getApiUrl +
            "/systemlog/" +
            this.logView +
            "?page=" +
            this.page +
            "&search=" +
            this.search +
            "&from=" +
            this.filter.dateFrom +
            "&to=" +
            this.filter.dateTo +
            "&users=[" +
            this.selectedUsers +
            "]",
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.items = response.data.data.data;
          this.length = response.data.data.last_page;
          this.page = response.data.data.current_page;
          if (this.length > 5) this.totalVisible = 5;
        });
    },
  },
  mounted() {
    this.logView = this.$route.params.viewLog;
    this.getDataFromApi();
    this.getAllUsers();
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

.icon-div {
  transition: 0.5s ease all;
}
</style>
